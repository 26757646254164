<template lang="pug">
CanvasModal.completed-modal(
  :is-show="true"
  @closeOverlay="closeVideoPreview"
)
  //- Note: comment out cause this is master designer side -//
  //- template(
  //-   v-if="showImageTabMenu"
  //-   #top
  //- )
  //-   .design-permutation-options.tab-2
  //-     .tab__head
  //-       button.btn-change-tab.tab__item(
  //-         type="button"
  //-         @click="templateActiveTab='scene_previews'"
  //-         :class="{ 'is-active': templateActiveTab === 'scene_previews' }"
  //-         v-html="`Scene previews`"
  //-       )
  //-       button.btn-change-tab.tab__item(
  //-         type="button"
  //-         @click="templateActiveTab='hero_wo_background_previews'"
  //-         :class="{ 'is-active': templateActiveTab === 'hero_wo_background_previews' }"
  //-         v-html="`Hero w/o background`"
  //-       )
  //-       button.btn-change-tab.tab__item(
  //-         type="button"
  //-         @click="templateActiveTab='hero_w_background_previews'"
  //-         :class="{ 'is-active': templateActiveTab === 'hero_w_background_previews' }"
  //-         v-html="`Hero with background`"
  //-        )

  .completed-modal__container
    //- template(
    //-   v-if="templateActiveTab === 'scene_previews' && Object.keys(scenePreviewLinks).length"
    //- )
    //-   .all-scenes-image-preview
    //-     div(v-for="preview in scenePreviewLinks")
    //-       img(
    //-         :src="preview.url"
    //-       )
    //-       span.scene-id {{preview.id}}

    //- template(
    //-   v-else-if="templateActiveTab === 'hero_wo_background_previews' || templateActiveTab === 'hero_w_background_previews'"
    //- )
    //-   .preview-tab-assets
    //-     ul
    //-       li(
    //-         v-for="(item, index) in previewAssetsWithoutBg"
    //-         :key="`preview-assets-${index}`"
    //-         :class="{'is-active' : item === activeHeroImage}"
    //-         @click="toggleHeroImage(item)"
    //-       )
    //-         img(
    //-           :src="getImage(item)"
    //-         )
    //-         span.list-text {{getImageName(item)}}

    //-   .project-preview(
    //-     ref="templatePreviewWrapper"
    //-   )
    //-     InspirationListItem(
    //-       :id="heroScene.id"
    //-       :width="heroScene.width"
    //-       :height="heroScene.height"
    //-       :background="heroScene.background"
    //-       :previews="heroScene.previews"
    //-       :hero="heroScene.hero"
    //-       :showEditButton="false"
    //-     )

    .completed-modal__option(v-if="!getIsTemplate")
      .tab-2
        .tab__body
          .completed-modal__title
            i.icon-download2
            p.title Export

          .tab__item
            .completed-modal__buttons
              BaseButton.button.download-button(
                :is-primary="true"
                :is-tall="true"
                :is-canvas="true"
                padding="16px 40px"
                @click="downloadVideo"
              ) Download

              BaseButton.button(
                extraClass="btn-upload-exported-video-wrapper"
                :is-icon="true"
                :is-hollow="true"
                :is-tall="true"
                :is-canvas="true"
                :is-disabled="!dropboxLoaded"
              )
                #dropbox-button
                  i.icon-dropbox

              //- Hide Share Google drive button
                BaseButton.button.google-drive__button(
                  extraClass="btn-upload-exported-video-wrapper"
                  :is-icon="true"
                  :is-hollow="true"
                  :is-tall="true"
                  :is-canvas="true"
                  padding="14px"
                  :is-disabled="!gdriveLoaded"
                )
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.55814 15.6063L2.35996 17.2066C2.52657 17.5435 2.76607 17.8082 3.04723 18.0007L5.91087 12.2734H0.183594C0.183594 12.6464 0.2669 13.0194 0.433511 13.3563L1.55814 15.6063Z" fill="white"/>
                    <path d="M10.0018 5.63882L6.90899 0C6.60534 0.18954 6.34667 0.450158 6.16672 0.781853L0.453509 11.2066C0.276875 11.5311 0.183829 11.8986 0.183594 12.2727H6.36916L10.0018 5.63882Z" fill="white"/>
                    <path d="M16.9576 18.0007C17.2387 17.8082 17.4782 17.5435 17.6448 17.2066L17.978 16.5448L19.5711 13.3563C19.7377 13.0194 19.821 12.6464 19.821 12.2734H14.0938L15.3124 15.0408L16.9576 18.0007Z" fill="#C9CED6"/>
                    <path d="M10.0032 5.72727L13.2759 0.274909C12.9546 0.0916364 12.5857 0 12.2048 0H7.80154C7.42072 0 7.05179 0.103091 6.73047 0.274909L10.0032 5.72727Z" fill="#C9CED6"/>
                    <path d="M14.0913 12.2734H5.90945L2.63672 17.7258C2.96919 17.9091 3.35092 18.0007 3.74496 18.0007H16.2558C16.6498 18.0007 17.0315 17.8976 17.364 17.7258L14.0913 12.2734Z" fill="#C9CED6"/>
                    <path d="M16.6993 5.99421L13.8394 0.781853C13.6593 0.450158 13.4003 0.18954 13.0963 0L10 5.63882L13.6368 12.2727H19.8182C19.8182 11.9055 19.7281 11.5383 19.548 11.2066L16.6993 5.99421Z" fill="white"/>
                  </svg>
                  #gdrive-buttoncomplete
          template(v-if="!isZipFile")
            .completed-modal__title
              i.icon-share1
              p.title Share

            .tab__item
              .completed-modal__buttons
                BaseButton.button.copy-link__button(
                  :class="{ 'is-copied': isLinkCopied }"
                  :is-primary="true"
                  :is-tall="true"
                  :is-canvas="true"
                  padding="16px 40px"
                  @click="copyLink"
                ) {{ !isLinkCopied ? 'Copy Link' : 'Copied' }}

                //- Hide Share FB button
                  BaseButton.button.fb-ad-manager__button(
                    :is-primary="true"
                    :is-tall="true"
                    :is-canvas="true"
                    padding="16px 20px"
                  ) FB Ad Manager

    .completed-modal__container--preview(
      v-if="!isZipFile"
    )
      .video-preview(
        ref="projectPreviewWrapper"
        :class="`is-${proportion}`"
      )
        .preview-play__button
          button.btn-play-video-preview(
            :class="{ 'is-playing': isPlaying }"
            @click="togglePlay"
            type="button"
          )
            i.icon.icon-play
          span.btn-play__background(:class="{ 'is-playing': isPlaying }")

        .project-preview__container
          .preview-project__duration(
            :class="{ 'is-playing': isPlaying}"
          )
            i.icon-clock
            span {{ getProjectDetails.duration }}s

          .canvas-scene__overlay(:class="{ 'is-playing': isPlaying }")

        .video-preview__container
          video(
            :class="['video-preview__video', `is-${proportion}`]"
            ref="videoPreview"
            loop
            preload
          )
            source(:src="videoUrl")
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { multiDimensionalUnique, isDesktopApp } from '@/assets/scripts/utilities';

import helperMixin from '@/components/mixins/helper-mixins';

import PreviewAssets from '@/assets/data/preview-assets.json';
import InspirationListItem from '@/components/SmartCreate/children/InspirationListItem.vue';

import Api from '@/services/api/Api';

export default {
  name: 'CompletedPreview',
  mixins: [helperMixin],
  components: {
    InspirationListItem,
  },
  data() {
    return {
      isShow: false,
      activeTab: 'download',
      videoTitle: 'Video Title',
      videoUrl: '',
      isPlaying: false,
      copyUrl: 'http://offeo.com/preview',
      uploadFile: {
        fileName: '',
        url: '',
      },
      emailFormat: {
        email: '',
      },
      emailList: [
        {
          email: '',
        },
      ],
      sendEmailText: 'Send',
      isSendingEmail: false,
      gdriveLoaded: false,
      dropboxLoaded: false,
      templateActiveTab: 'scene_previews',
      activeHeroImage: '',
      previewAssetsWithoutBg: PreviewAssets.withoutBg,
      isLinkCopied: false,
      isZipFile: false,
    };
  },
  computed: {
    ...mapState(['isRenderingCompleted']),
    ...mapGetters(['getIsTemplate']),
    ...mapGetters('canvasElements', ['getCanvasSize', 'getProjectDetails']),
    ...mapState('canvasElements', [
      'project',
      'canvasSize',
      'heroSceneWithoutBg',
      'heroSceneWithBg',
    ]),
    ...mapState('exportDetails', ['videoExportScene']),
    ...mapGetters('exportDetails', ['getExportDetailStates']),
    proportion() {
      if (this.getCanvasSize.width > this.getCanvasSize.height) return 'landscape';
      if (this.getCanvasSize.width < this.getCanvasSize.height) return 'vertical';
      return 'square';
    },
    scenePreviewLinks() {
      return multiDimensionalUnique(this.getExportDetailStates.scenePreviewLinks);
    },
    showImageTabMenu() {
      return this.getIsTemplate && Object.keys(this.scenePreviewLinks).length;
    },
    heroScene() {
      const heroSceneId = this.templateActiveTab === 'hero_wo_background_previews'
        ? this.heroSceneWithoutBg.id
        : this.heroSceneWithBg.id;

      const heroScene = {
        id: heroSceneId,
        width: this.canvasSize.width,
        height: this.canvasSize.height,
        previews: this.project.previews.images.hero_wo_bg,
        hero: this.getImage(this.activeHeroImage),
      };
      // console.log(heroScene);
      return heroScene;
    },
  },
  methods: {
    ...mapMutations(['setIsRenderingCompleted']),
    ...mapMutations('exportDetails', ['updateExportDetailState']),
    ...mapMutations('canvasElements', ['setTemporaryHeroImageUrl']),
    changeTab(value) {
      this.activeTab = value;
    },
    closeVideoPreview() {
      this.updateExportDetailState({
        isExporting: false,
        isCompleted: false,
        percent: 0,
      });
      // this.setIsRenderingCompleted(false);
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying;
    },
    addEmail() {
      const email = Object.assign({}, this.emailFormat);
      this.emailList.push(email);
    },
    updateData() {
      const { previewLink, cdnLink } = this.getExportDetailStates;
      const { projectId, name, duration } = this.getProjectDetails;

      const fileName = name || 'offeo-video';
      const fileExtension = cdnLink.split('.').pop();

      // eslint-disable-next-line
      let midDuration = parseInt(duration / 2);
      midDuration = midDuration < 1 ? 1 : midDuration;

      this.copyUrl = `${process.env.VUE_APP_PUBLIC_URL}/preview/${projectId}`;
      this.videoUrl = `${previewLink}#t=${midDuration}`; // seek to middle part of the video
      this.videoTitle = name;

      this.uploadFile.fileName = `${fileName
        .replace(/[^a-z0-9]/gi, '-')
        .toLowerCase()}.${fileExtension}`; // update to file name friendly
      this.uploadFile.url = cdnLink;

      this.isZipFile = fileExtension === 'zip';
      console.log('CompletedPreview:updateData', cdnLink, this.getExportDetailStates, this.isZipFile);
    },
    downloadVideo() {
      const { cdnLink } = this.getExportDetailStates;

      if (!cdnLink) return; // use exact cdn link instead of api download link

      const a = document.createElement('A');
      a.href = cdnLink;
      if (!isDesktopApp()) {
        a.target = '_blank';
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    initGdriveButton() {
      if (!this.uploadFile.fileName || !this.uploadFile.url) return;

      // eslint-disable-next-line
      gapi.savetodrive.render('gdrive-button', {
        src: this.uploadFile.url,
        filename: this.uploadFile.fileName,
        sitename: 'Offeo',
      });

      this.gdriveLoaded = true;
    },
    initDropboxButton() {
      if (!this.uploadFile.fileName || !this.uploadFile.url) return;

      const options = {
        files: [
          {
            filename: this.uploadFile.fileName,
            url: this.uploadFile.url,
          },
        ],
        // Success is called once all files have been successfully added to the user's
        // Dropbox, although they may not have synced to the user's devices yet.
        success() {
          // Indicate to the user that the files have been saved.
          // eslint-disable-next-line
          alert('Success! Files saved to your Dropbox.'); // TODO: update UI
          // console.log( 'uploaded-->', this.uploadFile.url );
          // document.getElementById("dropbox-button").appendChild(button);
        },

        // Progress is called periodically to update the application on the progress
        // of the user's downloads. The value passed to this callback is a float
        // between 0 and 1. The progress callback is guaranteed to be called at least
        // once with the value 1.
        progress() {},

        // Cancel is called if the user presses the Cancel button or closes the Saver.
        cancel() {},

        // Error is called in the event of an unexpected response from the server
        // hosting the files, such as not being able to find a file. This callback is
        // also called if there is an error on Dropbox or if the user is over quota.
        error() {},
      };
      // attach button
      // eslint-disable-next-line
      const button = Dropbox.createSaveButton(options);
      document.getElementById('dropbox-button').appendChild(button);
      this.dropboxLoaded = true;
    },
    sendEmails() {
      const emails = JSON.parse(JSON.stringify(this.emailList)).filter(
        el => el.email, // filter remove empty emails
      );

      if (emails.length === 0) return;

      // console.log('send email,', this.emailList, this.emailList.length, emails.length, emails);

      const { projectId } = this.getProjectDetails;

      this.sendEmailText = 'Sending...';
      this.isSendingEmail = true;

      const params = {
        project_id: projectId,
        emails: emails.map(v => v.email),
      };

      Api.sendPreviewEmails(params)
        .then((response) => {
          if (response.data && response.data.success) {
            // alert('Email sent');
            this.sendEmailText = 'Sent!';
            this.emailList = [
              {
                email: '',
              },
            ]; // reset input fields

            if (response.data.message) {
              // TODO: add ui for response
              // alert(response.data.message);
            }

            setTimeout(() => {
              this.sendEmailText = 'Send';
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.sendEmailText = 'Sending failed!';
        })
        .then(() => {
          // always executed
          this.isSendingEmail = false;
        });
    },
    copyLink() {
      this.isLinkCopied = true;
      navigator.clipboard.writeText(this.copyUrl);
    },
    getImage(item) {
      return `${process.env.VUE_APP_CDN_ASSETS}/smart-create/${item}`;
    },
    getImageName(item) {
      return item
        .split('.')
        .slice(0, -1)
        .join('.')
        .split('_')
        .join(' ');
    },
    toggleHeroImage(item) {
      // if (this.temporaryHeroImageUrl && this.activeHeroImage === item) {
      //   // remove
      //   this.activeHeroImage = '';
      //   this.setTemporaryHeroImageUrl('');
      // } else {
      this.activeHeroImage = item;
      this.setTemporaryHeroImageUrl(this.getImage(item));
      // }
    },
  },
  beforeMount() {
    // attach dropbox script
    const dropboxKey = process.env.VUE_APP_DROPBOX_KEY;
    const dropBox = document.createElement('script');
    dropBox.setAttribute('type', 'text/javascript');
    dropBox.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js');
    dropBox.setAttribute('id', 'dropboxjs');
    dropBox.setAttribute('data-app-key', dropboxKey);
    document.head.appendChild(dropBox);

    // attach gdrive script
    const gDrive = document.createElement('script');
    gDrive.setAttribute('type', 'text/javascript');
    gDrive.setAttribute('src', 'https://apis.google.com/js/platform.js');
    gDrive.setAttribute('id', 'gdrive');
    document.head.appendChild(gDrive);
  },
  mounted() {
    // this.isShow = this.isRenderingCompleted;
    this.isShow = this.getExportDetailStates.isCompleted;
    this.updateData();

    if (!this.activeHeroImage) {
      this.toggleHeroImage(this.previewAssetsWithoutBg[0]);
    }

    setTimeout(() => {
      this.initGdriveButton();
      this.initDropboxButton();
    }, 2000);
  },
  watch: {
    url() {
      // to reload the video when canvas element is updated
      this.$el.getElementsByTagName('video')[0].load();
    },
    isPlaying() {
      if (this.isPlaying) {
        this.$refs.videoPreview.currentTime = 0;
        this.$refs.videoPreview.play();
      } else {
        this.$refs.videoPreview.pause();
      }
    },
    isLinkCopied(val) {
      if (val) {
        setTimeout(() => {
          this.isLinkCopied = false;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss">
.completed-modal {
  .canvas-modal__overlay {
    background: rgba(0, 0, 0, 0.9);
    pointer-events: none !important;
  }

  .canvas-modal__closebtn {
    color: $light !important;
  }

  .canvas-modal__content {
    height: 680px;
    background: $darkGrey700;
    border-radius: 10px;
  }

  .canvas-modal__main {
    .content {
      padding: 0;
      overflow: hidden;
    }
  }
}

.completed-modal__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  .tab-2 {
    background: none;
    margin: auto 0;
  }

  .completed-modal__title {
    display: flex;
    color: $light;
    font-weight: 600;
    font-size: 20px;

    .title {
      margin-left: 18px;
    }

    .icon-download2 {
      font-size: 22px;
    }

    .icon-share1 {
      font-size: 23px;
    }
  }

  .tab__head {
    padding: 0;
    background: none;
    justify-content: flex-start;

    .tab__item {
      font-size: 18px;
      width: fit-content;
      background: none;
      flex: none;
      padding: 18px 0 5px;
      box-shadow: none;
      text-align: left;
      margin-right: 20px;

      &:hover,
      &.is-active {
        color: $light;
        font-weight: 600;
        background: none;
      }

      &.is-active {
        border-bottom: 2px solid $green700;
      }
    }
  }

  .btn-upload-exported-video-wrapper {
    position: relative;
    color: $light;
    border: 1px solid $lightGrey400;
    transition: 0.3s;
    padding: 0;
    height: 48px;
    width: 48px;

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      color: $blue3;
      background: none;
    }

    .btn__content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        position: initial;
      }
    }

    #dropbox-button {
      margin-bottom: -2px;

      .icon-dropbox {
        font-size: 1.375rem;
        margin-bottom: -2px;
      }
    }

    #gdrive-button,
    #dropbox-button {
      width: 100% !important;
      height: 100% !important;

      > a {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        height: 48px !important;
        width: 48px !important;
        opacity: 0;
        margin: auto;
        padding: 0;
        display: flex;
        align-items: center;
      }
    }

    &.google-drive__button {
      .btn__content {
        margin-bottom: -2px;
      }

      &:hover {
        path {
          fill: $blue3;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(6) {
            fill: $blue500;
          }
        }
      }
    }
  }

  .completed-modal__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .project__shareable-link {
      display: flex;
      align-items: center;
      position: relative;
      width: 85%;
      border: 1px solid var(--black-white);
      border-radius: 4px;
    }
  }

  .all-scenes-image-preview {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    > div {
      flex-direction: column;
      display: flex;
      max-width: calc(100% / 3 - 20px);
      margin: 10px;

      img {
        width: 100%;
        margin: 5px;
      }
    }
  }
}

.completed-modal__option,
.video-preview {
  height: auto;

  @include fb-requirement {
    height: 280px;
  }
}

.video-preview {
  position: relative;
  overflow: hidden;

  &.is-square {
    height: 420px;
    width: 420px;

    .btn-play__background {
      top: 45%;
      left: 45%;
    }
  }

  &.is-vertical {
    height: 500px;
    width: 282px;

    .btn-play__background {
      top: 45%;
      left: 43%;
    }
  }

  &.is-landscape {
    width: 450px;
    height: 256px;

    .btn-play__background {
      top: 40%;
      left: 44%;
    }

    .canvas-scene__overlay {
      bottom: 1px !important;

      @include smallest() {
        bottom: 2px !important;
      }
    }
  }

  @include fb-requirement {
    width: 280px;
  }

  &:hover {
    .btn-play-video-preview {
      color: $light;
    }

    .btn-play__background {
      background: $blue;
    }
  }
}

.video-preview__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &.is-square {
    width: 104%;
    height: 104%;
  }

  &.is-vertical {
    width: auto;
    height: 100%;
  }

  &.is-landscape {
    width: 100%;
    height: auto;
  }
}

.btn-play-video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 74.5px;
  cursor: pointer;
  z-index: 3;
  color: $blue;

  &.is-playing {
    opacity: 0;
  }

  &:not(.is-playing) {
    & ~ .video-preview__container {
      .video-preview__video {
        opacity: 0.4;
      }
    }
  }
}

.completed-modal__option {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  padding: 30px 0 30px 70px;
  background: $darkGrey5;

  .title {
    margin-top: 0;
    font-weight: 600;
  }

  .tab__body {
    height: auto;
  }

  .tab__item {
    transform: translateX(0);
    position: relative;
  }

  #video-link {
    width: 60%;

    input {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .input-field {
      color: var(--black-white);
      background: none;
      border: none;
    }
  }

  .btn-copy-link {
    position: absolute;
    right: 3px;
    border-radius: 5px;
    background: $darkGrey900;
    color: $light;
  }
}

.completed-modal__container--preview {
  display: flex;
  place-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  height: 100%;
  padding: 30px 0;
  background: $darkGrey700;

  .preview-play__button {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.3s;

    &:hover {
      .btn-play-project-preview {
        color: $light;
      }

      .btn-play__background {
        background: $blue;
      }
    }
  }

  .btn-play__background {
    position: absolute;
    width: 40px;
    height: 40px;
    background: $light;
    border-radius: 50%;
    z-index: 2;

    &.is-playing {
      opacity: 0;
    }
  }

  .project-preview__container {
    position: relative;
    width: 100%;
    height: 100%;

    .preview-project__duration {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      align-items: center;
      z-index: 1;
      color: $light;
      background: $darkGrey5;
      padding: 8px;
      border-radius: 4px;
      pointer-events: none;
      box-shadow: 5px 5px 5px 3px rgba($black, 0.3);
      opacity: 1;

      &.is-playing {
        opacity: 0;
      }

      .icon-clock {
        font-size: 15px;
      }

      span {
        font-size: 14px;
        margin-left: 8px;
      }
    }

    .canvas-scene__overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba($black, 0.8), rgba(0, 0, 0, 0) 100%);
      z-index: 1;

      &.is-playing {
        display: none;
      }
    }
  }
}

.completed-modal__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  .download-button {
    font-size: 16px;

    &:focus {
      background: $blue700;
    }
  }

  .copy-link__button,
  .fb-ad-manager__button {
    background: $darkGrey900;

    &:hover {
      background: $blue700;
      color: $light;
    }
  }

  .copy-link__button {
    width: 150px;

    &:hover {
      background: $blue700;
      color: $light;
    }

    &.is-copied {
      color: $green500;
    }
  }

  .button {
    margin-right: 8px;
  }

  .btn-container__option {
    position: absolute;
    padding: 5px;
    background: $light;
    box-shadow: 0 0px 15px rgba(50, 50, 50, 0.2);
    text-align: left;
    font-size: 0.875em;
    opacity: 0;
    visibility: hidden;

    button {
      padding: 12px 15px;
      width: 100%;

      &:hover {
        background: $defaultBorder;
      }
    }
  }
}
.design-permutation-options {
  text-align: center;
  display: flex;
  margin: -10px -30px;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 60px);
  overflow: auto;

  .tab__item {
    min-width: 220px;
  }

  button {
    background: transparent;
    cursor: pointer;

    &:not(:disabled):hover {
      &.btn-prev::before {
        border-right-color: $blue;
        transform: translateX(-5px);
      }
      &.btn-next::before {
        border-left-color: $blue;
        transform: translateX(5px);
      }
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      transition: transform 0.15s ease-in-out;
    }

    &:disabled::before {
      opacity: 0.25;
    }

    &.btn-prev::before {
      border-right: 12px solid $darkGrey;
    }

    &.btn-next::before {
      border-left: 12px solid $darkGrey;
    }
  }
}

.completed-modal__container {
  .preview-tab-assets {
    width: 50%;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style: none;
      max-height: 500px;
      overflow-y: auto;

      li {
        width: calc(100% / 3 - 20px);
        border: 1px solid #e1e4e8;
        margin: 10px;
        border-radius: 3px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.is-active {
          border: 2px solid $blue;
        }

        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }

        .icon {
          font-size: 2em;
        }

        .list-text {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          color: $darkGrey900;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
        }

        &.upload-your-own {
          justify-content: flex-end;
          padding: 10px;

          .icon {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .project-preview {
    margin-left: 30px;
    margin-top: 30px;

    .scenes__canvas {
      transform-origin: top left;
    }
  }
}
</style>
